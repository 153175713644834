import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../layouts'
import Feature from '../components/Feature'
import Sidebar from '../components/Sidebar'

const meta = {
  title: 'Remedial Repair Services',
  url: 'https://www.maxpowerlimited.com/remedial-repairs/',
}

const RemedialRepairsPage = props => (
  <Layout {...props}>
    <Helmet
      title={meta.title}
      meta={[
        { itemprop: 'name', content: meta.title },
        { property: 'og:title', content: meta.title },
        { property: 'og:url', content: meta.url },
        { name: 'twitter:title', content: meta.title },
        { name: 'twitter:url', content: meta.url },
      ]}
      link={[{ rel: 'canonical', href: meta.url }]}
    />

    <Feature title={meta.title} />

    <div className="page">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-8">
            <h2>Why is Electrical Remedial Work required?</h2>

            <p>
              An Electrical Installation Condition Report (EICR) assesses the
              safety of an installation. Where certain defects or damage is
              observed, remedial works are required to satisfy the duties
              imposed on duty holders by the Health and Safety at Work etc Act
              1974 and the Electricity at Work Regulations 1989, designed to
              ensure systems are safe for continued use.
            </p>

            <h4>Classification Codes</h4>

            <p>
              Each observation relating to a concern about the safety of the
              installation should be attributed an appropriate Classification
              Code selected from the standard codes C1, C2 and C3. Each code has
              a particular meaning:
            </p>

            <div className="panel">
              <p>
                <strong>Code C1</strong> - Danger present <br />
                Risk of injury. Immediate remedial action required.
              </p>
              <p>
                <strong>Code C2</strong> - Potentially dangerous <br />
                Urgent remedial action required
              </p>
              <p>
                <strong>Code C3</strong> - Improvement recommended
              </p>
            </div>

            <p>
              Only one of the standard Classification Codes should be attributed
              to each observation. If more than one Classification Code could be
              attributed to an observation, only the most serious one should be
              used (Code C1 being the most serious).
            </p>

            <p>
              Where the inspection and testing procedures identify an item which
              is dangerous or potentially dangerous, it should be identified in
              the inspection or test results schedule of the report by
              attributing to it a Classification Code C1 or C2, as appropriate,
              in the ‘outcome’ column of the inspection schedule or, where
              provided, the ‘remarks’ column of the test schedule.
            </p>

            <p>
              Where the inspection and testing procedures identify an item which
              is not dangerous or potentially dangerous, but for which
              improvement is recommended, it should be identified in the
              inspection or test results schedule of the report by attributing
              to it a Classification Code C3 in the ‘outcome’ column of the
              inspection schedule or, where provided, the ‘remarks’ column of
              the test schedule.
            </p>

            <p>
              Maxpower Limited are able to provide rectification works resulting
              from the non-compliances identified in any fixed wire inspection.
              Our remedial services will address any issues highlighted in your
              testing, with minimal interruption to your business and service.
            </p>

            <p>
              Any remedial works undertaken by us, will be verified and
              instantly updated on your client record. This ensures that the
              reports we make available to you and are a full and accurate
              picture of your electrical non-compliance.
            </p>
          </div>
          <div className="d-none d-lg-block col-lg-3 offset-lg-1">
            <Sidebar title={meta.title} />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default RemedialRepairsPage
